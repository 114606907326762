import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";

const ThemedBackground = () => {
    const auth = useAuth();
    const [imagePath, setImagePath] = useState('');


    useEffect(() => {
        console.log("theme change")
        let themeName = auth.userData?.profile.theme as String;
        const newPath = "/theme/" + themeName + "/background.jpg";

        setImagePath(newPath);

    }, [auth.userData?.profile.theme]);


    return (
        <img 
            src={imagePath} 
            alt='Logo' 
            style={{ zIndex: 2 }} 

            // onError={() => {
            //     // default Logo bei Fehler?
            //     const themeName = "wms";
            //     const newPath = "/theme/" + themeName + "/background.jpg";

            //     setImagePath(newPath);
            // }}
        />
    );
}

export default ThemedBackground;