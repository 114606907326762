/* tslint:disable */
/* eslint-disable */
/**
 * Target
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Job,
} from '../models';

export interface ChangeStateRequest {
    id: number;
    state: ChangeStateStateEnum;
}

export interface GetJobRequest {
    id: number;
}

export interface GetTargetRequest {
    id: number;
}

export interface PostJobRequest {
    job?: Job;
}

export interface PostMarryRequest {
    id: number;
    device: string;
}

export interface PushJobRequest {
    id: number;
    job?: Job;
}

/**
 * 
 */
export class JobApi extends runtime.BaseAPI {

    /**
     * Führt die Änderung des aktuellen Zustands aus. Die Aktionen welche ausgeführt werden sind bei den Parametern näher beschrieben
     */
    async changeStateRaw(requestParameters: ChangeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Job>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeState.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling changeState.');
        }

        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job/{id}/state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Führt die Änderung des aktuellen Zustands aus. Die Aktionen welche ausgeführt werden sind bei den Parametern näher beschrieben
     */
    async changeState(requestParameters: ChangeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Job> {
        const response = await this.changeStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liefert den aktuellen Job zurück mit allen Informationen
     */
    async getJobRaw(requestParameters: GetJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Job>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Liefert den aktuellen Job zurück mit allen Informationen
     */
    async getJob(requestParameters: GetJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Job> {
        const response = await this.getJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Listet alle Jobs für den Aktiven Nutzer auf (Für ein Device maximal 1 und für einen User alle vorhandenen) 
     */
    async getJobsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Job>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Listet alle Jobs für den Aktiven Nutzer auf (Für ein Device maximal 1 und für einen User alle vorhandenen) 
     */
    async getJobs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Job>> {
        const response = await this.getJobsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Liefert den aktuellen Job zurück mit allen Informationen
     */
    async getTargetRaw(requestParameters: GetTargetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTarget.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job/{id}/target`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Liefert den aktuellen Job zurück mit allen Informationen
     */
    async getTarget(requestParameters: GetTargetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getTargetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Startet eine neue Target Ausrichtung. Sollte für das übergebene Fahrzeug keine Daten vorhanden sein oder die Targets nicht im aktuellen Abo enthalten, wird mit den entsprechenden  HTTP-Codes geantwortet. 
     */
    async postJobRaw(requestParameters: PostJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Job>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.job,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Startet eine neue Target Ausrichtung. Sollte für das übergebene Fahrzeug keine Daten vorhanden sein oder die Targets nicht im aktuellen Abo enthalten, wird mit den entsprechenden  HTTP-Codes geantwortet. 
     */
    async postJob(requestParameters: PostJobRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Job> {
        const response = await this.postJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verheiratet einen Auftrag mit einem Target-Screen Device
     */
    async postMarryRaw(requestParameters: PostMarryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Job>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postMarry.');
        }

        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError('device','Required parameter requestParameters.device was null or undefined when calling postMarry.');
        }

        const queryParameters: any = {};

        if (requestParameters.device !== undefined) {
            queryParameters['device'] = requestParameters.device;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job/{id}/marry`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Verheiratet einen Auftrag mit einem Target-Screen Device
     */
    async postMarry(requestParameters: PostMarryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Job> {
        const response = await this.postMarryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Aktualisiert einen Job mit den benötigten Zusatzinformationen. Sollte der State geändert werden so muss dafür der eigene Endpoint verwendet werden 
     */
    async pushJobRaw(requestParameters: PushJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Job>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pushJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.job,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Aktualisiert einen Job mit den benötigten Zusatzinformationen. Sollte der State geändert werden so muss dafür der eigene Endpoint verwendet werden 
     */
    async pushJob(requestParameters: PushJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Job> {
        const response = await this.pushJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ChangeStateStateEnum = {
    Created: 'Created',
    CalibrateDistance: 'CalibrateDistance',
    DisplayTarget: 'DisplayTarget',
    Completed: 'Completed',
    Aborted: 'Aborted',
    UnknownDefaultOpenApi: '11184809'
} as const;
export type ChangeStateStateEnum = typeof ChangeStateStateEnum[keyof typeof ChangeStateStateEnum];
