import { Bluetooth, Cloud, Usb } from "@mui/icons-material";
import { Card, CardContent, IconButton } from "@mui/material";
import { useAuth } from "oidc-react";
import { useEffect, useRef, useState } from "react";

const StatusBar = () => {
    const auth = useAuth();

    const [colorCloud, setColorCloud] = useState('gray');
    const [colorStuetzen, setcolorStuetzen] = useState('gray');
    const [colorLaser, setcolorLaser] = useState('gray');

    const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
        const abortController  = new AbortController();

        const endpoint = "http://localhost:8099/health";
        const options:RequestInit = {
            method: 'GET',
            // headers: {'Content-Type':'application/json'},
        }

        const fetchData = async () => {
            try {
                const response = await fetch(endpoint, {
                    ...options,
                    signal: abortController.signal,
                });

                if(response){
                    const data = await response.json();

                    console.log(data)
                    const bluetoothStatus = data.components.bluetooth.status;
                    console.log("Bluetooth Status:", bluetoothStatus);
                    if (bluetoothStatus === 'UP') {
                        setcolorLaser('green');
                    } else {
                        setcolorLaser('red');
                    }
    
                    const cloudStatus = data.components.cloud.status;
                    console.log("Cloud Status:", cloudStatus);
                    console.log(auth.userData?.profile?.name?.toString());
                    // console.log(auth.userData);
                    if (cloudStatus === 'UP') {
                        setColorCloud('green');
                    } else {
                        setColorCloud('red');
                    }
    
                    const usbStatus = data.components.usb.status;
                    console.log("USB Status:", usbStatus);
                    if (usbStatus === 'UP') {
                        setcolorStuetzen('green');
                    } else {
                        setcolorStuetzen('red');
                    }
                }
                
            } catch (error: any) {
                if (error.name === 'AbortError') {
                    console.log('Request aborted');
                } else {
                    console.log('Error:', error);
                }
            }


            timeoutRef.current = setTimeout(fetchData, 5000);
        }

        fetchData();
      
        return () => {
            if(timeoutRef && timeoutRef.current){
                clearTimeout(timeoutRef.current);
            }

            // Abort the request when the component unmounts or when a dependency changes
            abortController.abort();
        };

    }, [auth.userData?.profile?.name]);


    return (
        <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 3, display: 'flex', flexDirection: 'row', alignItems: 'flex-end', padding: '50px' }}>

            <Card style={{ marginRight: '50px', borderRadius: '15px' }}>
                <IconButton ><Cloud style={{ width: '10rem', height: '10rem', color: colorCloud }} /></IconButton>
                <CardContent>
                    <div style={{ color: colorCloud }}>Cloud</div>
                </CardContent>
            </Card>
            <Card style={{ marginRight: '50px', borderRadius: '15px' }}>
                <IconButton><Usb style={{ width: '10rem', height: '10rem', color: colorStuetzen }} /></IconButton>
                <CardContent>
                    <div style={{ color: colorStuetzen }}>Stützen</div>
                </CardContent>
            </Card>
            <Card style={{ marginRight: '50px', borderRadius: '15px' }}>
                <IconButton><Bluetooth style={{ width: '10rem', height: '10rem', color: colorLaser }} /></IconButton>
                <CardContent>
                    <div style={{ color: colorLaser }}>Laser</div>
                </CardContent>
            </Card>
        </div>
    );
}

export default StatusBar;