import React, { useState } from 'react';

import './App.css';
import ImageDisplay from './components/ImageDisplay';
import { AuthProvider, User } from 'oidc-react';




function App() {

  

  const oidcConfig = {
    onSignIn: (userData: User) => {
      // Redirect?
      console.log("Signed In")
      
    },
    authority: 'https://auth.data2work.com/realms/d2wDevices',
    clientId: 'target',
    redirectUri: 'https://target.data2work.com/',
    //redirectUri: 'http://localhost:3000',
  };

  

  return (    
    <AuthProvider {...oidcConfig} autoSignIn={true} onSignIn={(userData) => {
      console.log(userData?.profile.name);
      
    }}>
      <div className="App">
        <header className="App-header">
          <ImageDisplay />
        </header>
      </div>
    </AuthProvider>
  );
}

export default App;
