import React, { useEffect, useRef, useState } from 'react';
import { Configuration, JobApi } from "../generated/job";
import { useAuth } from "oidc-react";
import StatusBar from './StatusBar/StatusBar';
import ThemedBackground from './ThemesBackground/ThemedBackground';

const ImageDisplay = () => {
    const [imageData, setImageData] = useState('');
    const [error, setError] = useState('');
    const auth = useAuth();

    const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {

        if (auth.userData?.access_token != null) {
            let configuration: Configuration = new Configuration({ 'accessToken': auth.userData.access_token });
            let api: JobApi = new JobApi(configuration);
            // apiRef.current = new JobApi(configuration);

            const load = async () => {
                try {
                    const data = await api?.getJobs();

                    if (data && data.length > 0) {
                        const target = await api?.getTarget({ id: data[0].id! });

                        if (target) {
                            console.log(target);
                            setImageData(URL.createObjectURL(target));
                        }
                        else {
                            setImageData("");
                        }


                    }
                    else {
                        setImageData('');
                    }

                } catch (error) {
                    console.log("fetch failed");
                }


                timeoutRef.current = setTimeout(load, 1000);
            }

            load();
        }


        return () => {
            if (timeoutRef && timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };

    }, [auth.userData?.access_token]);

    const loading = imageData === null || imageData.trim().length === 0;

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>

            {auth.isLoading &&
                <p>Loading ...</p>
            }
            {!auth.isLoading &&
                <div>
                    <div>
                        {loading ? (
                            <div style={{ position: 'relative' }}>
                                <StatusBar />
                                <ThemedBackground />
                            </div>
                        ) : (
                            imageData && <img src={imageData} alt="Base64" />
                        )}
                    </div>
                </div>
            }

        </div>


    );
};

export default ImageDisplay;